/*
* ==========================================================
*      Dropzone
* ==========================================================
*/

@keyframes passing-through {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
  
    30%,
    70% {
      opacity: 1;
      transform: translateY(0px);
    }
  
    100% {
      opacity: 0;
      transform: translateY(-40px);
    }
  }
  
  
  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
  
    30% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
  
    10% {
      transform: scale(1.1);
    }
  
    20% {
      transform: scale(1);
    }
  }
  
  .dropzone {
    min-height: 150px;
    border: 1px dashed $gray-300;
    border-radius: $border-radius;
    background: white;
    padding: 1rem 1rem 0;
    
  
    &.dz-clickable {
      cursor: pointer;
  
      * {
        cursor: default;
      }
  
      .dz-message {
        cursor: pointer;
  
        * {
          cursor: pointer;
        }
      }
    }
  
    &.dz-started .dz-message {
      display: none;
    }
  
    &.dz-drag-hover {
      border-style: solid;
  
      .dz-message {
        opacity: 0.5;
      }
    }
  
    .dz-message {
      text-align: center;
      margin: 2rem 0 3rem;
    }
  
    .dz-preview {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem;
      min-height: 100px;
  
      &:hover {
        z-index: 1000;
  
        .dz-details {
          opacity: 1;
        }
      }
  
      &.dz-file-preview {
        .dz-image {
          background: #999;
          background: linear-gradient(to bottom, #eee, #ddd);
        }
  
        .dz-details {
          opacity: 1;
        }
      }
  
      &.dz-image-preview {
        background: white;
  
        .dz-details {
          transition: opacity 0.2s linear;
        }
      }
  
      .dz-remove {
        font-size: 14px;
        text-align: center;
        display: block;
        cursor: pointer;
        border: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      &:hover .dz-details {
        opacity: 1;
      }
  
      .dz-details {
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        font-size: 13px;
        min-width: 100%;
        max-width: 100%;
        padding: 2em 1em;
        text-align: center;
        color: rgba(0, 0, 0, 0.9);
        line-height: 150%;
  
        .dz-size {
          margin-bottom: 1em;
          font-size: 16px;
        }
  
        .dz-filename {
          white-space: nowrap;
  
          &:hover span {
            border: 1px solid rgba(200, 200, 200, 0.8);
            background-color: rgba(255, 255, 255, 0.8);
          }
  
          &:not(:hover) {
            overflow: hidden;
            text-overflow: ellipsis;
  
            span {
              border: 1px solid transparent;
            }
          }
  
          span {
            background-color: rgba(255, 255, 255, 0.4);
            padding: 0 0.4em;
            border-radius: 3px;
          }
        }
  
        .dz-size span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px;
        }
      }
  
      &:hover .dz-image img {
        transform: scale(1.05, 1.05);
        filter: blur(8px);
      }
  
      .dz-image {
        overflow: hidden;
        width: 120px;
        height: 120px;
        position: relative;
        display: block;
        z-index: 10;
        border-radius: $border-radius;
  
        img {
          display: block;
        }
      }
  
      &.dz-success .dz-success-mark {
        animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
  
      &.dz-error .dz-error-mark {
        opacity: 1;
        animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
  
      .dz-success-mark,
      .dz-error-mark {
        pointer-events: none;
        opacity: 0;
        z-index: 500;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 50%;
        background: rgba($white, .3);
        color: $white;
        margin-top: -1.5rem;
        margin-left: -1.5rem;
      }
  
      &.dz-processing .dz-progress {
        opacity: 1;
        transition: all 0.2s linear;
      }
  
      &.dz-complete .dz-progress {
        opacity: 0;
        transition: opacity 0.4s ease-in;
      }
  
      &:not(.dz-processing) .dz-progress {
        animation: pulse 6s ease infinite;
      }
  
      .dz-progress {
        opacity: 1;
        z-index: 1000;
        pointer-events: none;
        position: absolute;
        height: 8px;
        left: 50%;
        top: 50%;
        margin-top: -4px;
        width: 80px;
        margin-left: -40px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        overflow: hidden;
  
        .dz-upload {
          background: #333;
          background: linear-gradient(to bottom, #666, #444);
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0;
          transition: width 300ms ease-in-out;
        }
      }
  
      &.dz-error {
        .dz-error-message {
          display: block;
        }
  
        &:hover .dz-error-message {
          opacity: 1;
          pointer-events: auto;
        }
      }
  
      .dz-error-message {
        pointer-events: none;
        z-index: 1000;
        position: absolute;
        display: block;
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease;
        border-radius: 3px;
        font-size: 13px;
        top: 130px;
        left: -10px;
        width: 140px;
        background: #be2626;
        background: linear-gradient(to bottom, #be2626, #a92222);
        padding: 0.5em 1.2em;
        color: white;
  
        &:after {
          content: '';
          position: absolute;
          top: -6px;
          left: 64px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #be2626;
        }
      }
    }
  }