@import url("https://fonts.googleapis.com/css?family=Inter");

body {
  font-family: "Inter", sans-serif;
}

.page-body {
  margin-top: 150px !important;
  margin-left: 320px !important;
  background-color: #fff !important;
}

.page-main-header {
  margin-left: 300px !important;

  .main-header-right {
    border-radius: 0% !important;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 991px) {
  .page-main-header {
    margin-left: 0px !important;
    height: 90px !important;
    .main-header-right .nav-right > ul {
      top: 100px !important;
    }
  }
  .page-body {
    margin-top: 100px !important;
  }

  .page-sidebar {
    top: 90px !important;
    height: calc(100vh - 130px) !important;
    &.open {
      margin-left: calc(-300px) !important;
    }
  }

  .sidebar {
    height: calc(100vh - 130px) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.loader-wrapper {
  background-color: #fff;
}
.avatar {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: $border-color;
  border-radius: 50%;
  background: $white;
  box-shadow: none;
  line-height: 3rem;
  object-fit: contain;

  &.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.avatar-lg {
    width: 5rem;
    height: 5rem;
  }

  &.avatar-xl {
    width: 7rem;
    height: 7rem;
  }

  &.avatar-xxl {
    width: 10rem;
    min-width: 10rem;
    height: 10rem;
  }

  &.avatar-border-white {
    border: solid 2px $white;
  }
}

.avatar-square {
  border-radius: 10px;
  width: 100%;
}

.avatar-large {
  border-radius: 10px;
  width: 50%;
  height: auto;
}

.avatar-sm {
  width: 40px;
  border-radius: 10px;
  margin-right: 10px;
}

.button-vertical-center {
  display: inline-flex;
  align-items: center;
}

.pdf-preview {
  width: 200px;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.toast-top-right {
  position: sticky;
  top: 3rem;
  right: 0;
  z-index: 100;
}

.blog-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.blog-editor-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  // height: 400px !important;
}

.blog-html-content {
  border: 1px solid #f1f1f1;
  padding: 5px;
  width: 100%;
  border-radius: 2px;
  resize: none;
  // height: 200px;
}

.anchor-button {
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid dodgerblue;
  color: dodgerblue;
  text-align: center;
  padding: 0.5em;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
}
.anchor-button:hover {
  background-color: dodgerblue;
  color: #fff;
}

.theme-form {
  label {
    font-weight: bold;
  }

  h2 {
    margin-bottom: 30px;
  }
}

.line-height-inherit {
  line-height: inherit;
}

.selectpicker {
  width: 100%;
  width: 220px;
}

.success-toast {
  position: fixed;
  width: 100%;
  z-index: 100;
  right: 20px;
  top: 150px;
}
.dashboard-login {
  padding: 0px;
  background-image: url("../images/UK_BigBen.jpg");
  background-size: cover;
}
.background-login {
  width: 100%;
  padding: 0px;
}

.button-signin {
  background-color: #cc0000;
  border-radius: 25px;
  color: #fff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.form-signin-field {
  border-radius: 0%;
  border-bottom: 2px solid #cc0000;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 18px;
}
.main-header-left-2 {
  height: 150px;
}
.logo-dashboard-sidebar {
  align-content: center;
  margin: 10%;
}

.color4-sidebar {
  background-color: #cc0000;
}
.page-main-header {
  background-color: #fff;
  box-shadow: none;
}

.main-header-right {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(30px);
  box-shadow: none;
}
.font-weight-bold-Moto {
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  padding-top: 10px;
  color: #272835;
}
.page-body-wrapper {
  .page-sidebar {
    border-radius: 20px;
    width: 295px;
    height: 880px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    box-shadow: -5px 5px 10px rgba(65, 131, 209, 0.4);
    z-index: 100 !important;

    .sidebar-menu {
      > li {
        &.active {
          > a {
            background-color: #fff6 !important;
            border-radius: 15px !important;
          }

          .menuItemTitle {
            border-left: solid white;
            padding: 4px;
          }
        }
        > a {
          &:hover {
            background-color: #fff6 !important;
            border-radius: 15px !important;
          }
          &.active {
            background: #fff6 !important;
            color: #fff !important;
          }
        }
      }

      .sidebar-submenu {
        > li {
          > a {
            &.active {
              .childItemTitle {
                border-left: solid white;
              }
            }
            .childItemTitle {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
.col-sm-12 {
  padding-top: 15px;
}
.btn-success-gradien {
  border-radius: 25px;
}
.btn-secondary-gradien {
  border-radius: 25px;
}
.btn-warning-gradien {
  border-radius: 25px;
}
.btn-info-gradien {
  border-radius: 25px;
}
.close-icon {
  margin-top: 5px;
}
.dropzone {
  padding-top: 0px;
}

.card-poster {
  width: 270px;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #fff;
  border: none;
  overflow: hidden;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075);
  height: 350px;

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.stacked_avatars {
  display: flex;
  list-style-type: none;
  margin: auto;
  padding: 0px;
  flex-direction: row;
  &__item {
    background-color: #596376;
    border: 2px solid #1f2532;
    border-radius: 100%;
    color: #ffffff;
    display: block;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 100;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -10px;
    img {
      width: 100%;
    }
  }
}

.DateRangePicker_picker {
  z-index: 10000 !important;
}

.SingleDatePicker_picker {
  z-index: 10000 !important;
}

.select__menu {
  z-index: 10000 !important;
}

.footer-copyright {
  position: absolute;
  height: 80px;
  bottom: 0.1px;
  background-color: #c00;
  border-radius: 0px 0px 20px 20px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    text-align: center;
  }
}

.line-footer {
  width: 70%;
  background-color: #fff6;
  size: 3px;
}

.sidebar {
  padding-bottom: 100px;
}

.light-overlay,
.dark-overlay,
.gradient-overlay {
  position: relative;
  overflow: hidden;

  .overlay-content {
    position: absolute;
    z-index: 20;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    opacity: 0.3;
    background: #000;
  }
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.icon-rounded {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  line-height: 4rem;

  svg,
  i {
    vertical-align: middle;
  }

  &.icon-rounded-sm {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
  &.icon-rounded-lg {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
  }
  &.icon-rounded-xl {
    width: 7rem;
    height: 7rem;
    line-height: 7rem;
  }
}

.svg-icon {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  stroke: currentColor;
  stroke-width: 3;

  --layer1: currentColor;
  --layer2: currentColor;

  &.svg-icon-light {
    stroke-width: 2;
  }

  &.svg-icon-heavy {
    stroke-width: 4;
  }

  &.svg-icon-md {
    width: 30px;
    height: 30px;
  }

  &.svg-icon-lg {
    width: 36px;
    height: 36px;
  }
}

.page-main-header .main-header-right .nav-right.right-menu ul li:nth-child(2) {
  border: none;
}

.MuiTableCell-head {
  background-color: #f4f2ff !important;
}

.MuiTableCell-body {
  cursor: pointer;
}

.MuiToolbar-root {
  margin: 10px 0px;
}

.date-range-picker-filter {
  .DateRangePicker {
    .DateRangePickerInput {
      .DateInput {
        width: 45%;
        height: 38px;
        display: inline-flex;

        .DateInput_input {
          font-size: 14px;
        }
      }
    }
  }
}

.card-poster-square {
  flex-direction: row;
  align-items: flex-end;
  border: none;
  overflow: hidden;
  height: 300px;
  width: 300px;
  color: #fff;
  position: relative;
}

.avatar-square {
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  margin-top: 5px;
  margin-right: 5px;
}

.avatar-rect {
  width: 60px;
  height: 40px;
  border-radius: 5px !important;
  margin-top: 5px;
  margin-right: 5px;
}

.pm-progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 5px;

  :nth-child(1) {
    border-radius: 5px 0px 0px 5px;
  }
  :nth-child(6) {
    border-radius: 0px 5px 5px 0px;
  }

  .pm-progress-bar-section {
    height: 10px;
    float: left;
  }

  .pm-progress-bar-text {
    bottom: 15px;
    position: relative;
    font-size: xx-small;
    width: 110%;
  }
}

.react-kanban-column {
  border-radius: 10px !important;
  width: 260px !important;
  background-color: white !important;
  box-shadow: 0 0 15px 10px #f7f7f7 !important;
}

.card .card-header h5:not(.mb-0) {
  font-family: inherit !important;
}

// card styles
/*-----------------------------------------------
|   Chat Page
-----------------------------------------------*/

.card-chat {
  height: calc(70vh);
  z-index: 1;

  // overflow: hidden;
  .card-body {
    position: relative;
    overflow: hidden;
  }
}

.card-chat-pane {
  height: calc(100% - 2.063rem);
  position: relative;
  overflow: hidden;
}

.card-chat-content {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 100%;
}

/*-----------------------------------------------
  |   Chat Sidebar
  -----------------------------------------------*/
.chat-sidebar {
  position: absolute;
  height: 100%;
  z-index: 11;
  top: 0;
  left: -100%;
  width: 100%;
  transition: all 0.5s ease;
  overflow: hidden;
}

.contacts-list {
  height: calc(100% - 2rem);
  border-right: 1px solid $border-color;
  overflow-y: auto;
  width: 100%;
}

.chat-contact {
  align-items: center;
  padding: map-get($spacers, 3);
  height: 4.188rem;
  cursor: pointer;
  border-top: 1px solid transparent;
  background-color: $white;

  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0.25rem;
    left: 0;
    top: 0;
  }

  &:first-child {
    border-top: 0 !important;
  }

  @include hover-focus() {
    &:after {
      background-color: $success;
    }
  }

  &.active {
    &:after {
      background-color: $success;
    }
  }

  &.active:not(:first-child),
  &.active + .chat-contact {
    border-top: 1px solid $border-color;
  }
}

.chat-contact-body {
  position: relative;
  min-width: 0;
}

.chat-contact-title {
  font-weight: $font-weight-normal;
}

.message-time {
  color: $gray-400;
}

.chat-contact-content {
  //   font-size: map-get($font-sizes, "-1");
  color: $gray-600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unread-message {
  color: $black;

  .chat-contact-title,
  .chat-contact-content {
    // font-weight: $font-weight-semi-bold;
    color: $black;
  }

  .message-time {
    color: $primary;
  }
}

// Contacts search style
.contacts-search-wrapper {
  position: relative;
  border-top: 1px solid $border-color;
  border-right: 1px solid $border-color;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.063rem;
}

.chat-contacts-search {
  padding-left: 2.12rem;

  @include hover-focus {
    box-shadow: none;
  }
}

.contacts-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $gray-400;
  //   font-size: map-get($font-sizes, "-1");
  left: 1rem;
}

/*-----------------------------------------------
  |   Chat contents
  -----------------------------------------------*/
.chat-content-header {
  padding: map-get($spacers, 3);
  border-bottom: 1px solid $border-color;
}

.chat-content-body {
  height: calc(100% - 4.25rem);

  .scroll-content {
    position: relative;
  }
}

.chat-content-scroll-area {
  height: 100%;
  padding-bottom: map-get($spacers, 3);
  overflow-y: auto;
}

.chat-message {
  max-width: calc(100% - 7rem);
  display: inline-block;
  position: relative;
}

.message-settings {
  position: absolute;
  bottom: -0.9375rem;
  border: 1px solid $gray-200;
  background-color: $gray-100;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  text-align: center;
}

.message-settings-left {
  right: -0.9375rem;
}

.message-settings-right {
  left: -0.9375rem;
}

.conversation-info {
  position: absolute;
  width: 15rem;
  right: -100%;
  background-color: $gray-100;
  border-left: 1px solid $border-color;
  z-index: 10;
  top: 4.25rem;
  height: calc(100% - 4.25rem);
  transition: 0.5s;

  &.show {
    right: 0;
  }

  .conversation-info-icon {
    width: 1.25rem;
    // font-size: map_get($font-sizes, 0);
  }
}

.chat-file-upload {
  position: absolute;
  right: 5.5rem;
  z-index: 2;
  top: 0.125rem;
  color: $gray-500;

  @include hover-focus {
    color: $gray-600;
  }
}
.emoji-icon {
  position: absolute;
  top: 8px;
  right: 64px;
  cursor: pointer;
  color: $gray-500;

  @include hover-focus {
    color: $gray-600 !important;
  }
}

.btn-message-submit {
  position: absolute;
  right: 3.938rem;
  top: 0.1875rem;
}

.btn-send {
  position: absolute;
  top: 0.125rem;
  z-index: 2;
  right: 1rem;
}

.btn-send {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  color: $gray-500;
}

.chat-editor-area {
  // border-top: 1px solid $border-color;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  width: 100%;

  // Emojionearea editor style for chat page
  .emojionearea-editor {
    // min-height: 1.875rem;
    min-height: 2rem;
    padding-right: 7rem;
  }

  .emojionearea-button {
    right: 3.75rem;
  }
}

@include media-breakpoint-up(sm) {
  .contacts-list {
    height: calc(100% - 2.063rem);
  }

  .conversation-scroll-area {
    height: calc(100% - 2.188rem);
  }

  .card-chat {
    height: calc(70vh);
  }
}

@include media-breakpoint-up(md) {
  .chat-sidebar {
    position: relative;
    height: 100%;
    top: 0;
    left: 0 !important;
    width: auto;
    transition: none;
  }
}

@include media-breakpoint-up(lg) {
  .chat-sidebar {
    flex: 0 0 17.5rem;
    max-width: 17.5rem;
  }
}

@include media-breakpoint-up(xxl) {
  .chat-sidebar {
    flex: 0 0 21.88rem;
    max-width: 21.88rem;
  }
}

// Specific styles for edge
.safari {
  .contacts-list {
    height: calc(100% - 2.063rem);
  }

  .contacts-search-wrapper {
    height: 2.125rem;
  }
}

// Specific styles for ie
.ie {
  .chat-contact {
    height: 4.25rem;
  }

  .chat-gallery {
    width: calc(100% - 7rem);
  }

  .conversation-info {
    top: 4.313rem;
  }
}

// Specific styles for edge
.edge {
  .chat-contact {
    height: 4.25rem;
  }

  .conversation-info {
    top: 4.313rem;
  }
}
// individual massage thread option on hover color
.chat-option-hover {
  transition: 0.2s;
  @include hover-focus {
    color: $primary;
  }
}

.hover-actions-trigger {
  position: relative;

  // Style for email Inbox
  .inbox-link {
    color: inherit;
    text-decoration: none;
  }
  @include hover-focus {
    .hover-actions {
      z-index: 1;
      display: flex;
    }
    .hover-hide {
      display: none;
    }
  }
}

.rounded-soft {
  border-radius: 0.375rem;
}
